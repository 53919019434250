<template>
  <div>
    <meta name="robots" content="noindex" />
    <h1 class="policy-terms-h1">Privacy Policy</h1>
    <p class="policy-terms-p">Last updated: July 19, 2023</p>
    <p class="policy-terms-p">
      This Privacy Policy describes Our policies and procedures on the
      collection, use and disclosure of Your information when You use the
      Service and tells You about Your privacy rights and how the law protects
      You.
    </p>
    <p class="policy-terms-p">
      We use Your Personal data to provide and improve the Service. By using the
      Service, You agree to the collection and use of information in accordance
      with this Privacy Policy. This Privacy Policy has been created with the
      help of the
      <a
        href="https://www.privacypolicies.com/privacy-policy-generator/"
        target="_blank"
        >Privacy Policy Generator</a
      >.
    </p>

    <h2 class="policy-terms-h2">Interpretation and Definitions</h2>
    <h2 class="policy-terms-h2">Interpretation</h2>
    <p class="policy-terms-p">
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural.
    </p>

    <h2 class="policy-terms-h2">Definitions</h2>
    <p class="policy-terms-p">For the purposes of this Privacy Policy:</p>
    <ul class="policy-terms-list">
      <li class="policy-terms-item">
        <strong>Account</strong> means a unique account created for You to
        access our Service or parts of our Service.
      </li>
      <li class="policy-terms-item">
        <strong>Affiliate</strong> means an entity that controls, is controlled
        by or is under common control with a party, where "control" means
        ownership of 50% or more of the shares, equity interest or other
        securities entitled to vote for election of directors or other managing
        authority.
      </li>
      <li class="policy-terms-item">
        <strong>Application</strong> refers to Carculator 2.0, the software
        program provided by the Company.
      </li>
      <li class="policy-terms-item">
        <strong>Company</strong> (referred to as either "the Company", "We",
        "Us" or "Our" in this Agreement) refers to Group Salvador Caetano, Av.
        Vasco da Gama 1410, 4430-247 Vila Nova de Gaia, Portugal.
      </li>
      <li class="policy-terms-item">
        <strong>Country</strong> refers to: Portugal
      </li>
      <li class="policy-terms-item">
        <strong>Device</strong> means any device that can access the Service
        such as a computer, a cellphone or a digital tablet.
      </li>
      <li class="policy-terms-item">
        <strong>Personal Data</strong> is any information that relates to an
        identified or identifiable individual.
      </li>
      <li class="policy-terms-item">
        <strong>Service</strong> refers to the Application.
      </li>
      <li class="policy-terms-item">
        <strong>Service Provider</strong> means any natural or legal person who
        processes the data on behalf of the Company. It refers to third-party
        companies or individuals employed by the Company to facilitate the
        Service, to provide the Service on behalf of the Company, to perform
        services related to the Service or to assist the Company in analyzing
        how the Service is used.
      </li>
      <li class="policy-terms-item">
        <strong>Usage Data</strong> refers to data collected automatically,
        either generated by the use of the Service or from the Service
        infrastructure itself (for example, the duration of a page visit).
      </li>
      <li class="policy-terms-item">
        <strong>You</strong> means the individual accessing or using the
        Service, or the company, or other legal entity on behalf of which such
        individual is accessing or using the Service, as applicable.
      </li>
    </ul>

    <h1 class="policy-terms-h1">Collecting and Using Your Personal Data</h1>
    <h2 class="policy-terms-h2">Types of Data Collected</h2>
    <h3 class="policy-terms-h2">Personal Data</h3>
    <p class="policy-terms-p">
      While using Our Service, We may ask You to provide Us with certain
      personally identifiable information that can be used to contact or
      identify You. Personally identifiable information may include, but is not
      limited to:
    </p>
    <ul class="policy-terms-list">
      <li class="policy-terms-item">Email address</li>
      <li class="policy-terms-item">First name and last name</li>
      <li class="policy-terms-item">Phone number</li>
      <li class="policy-terms-item">Usage Data</li>
    </ul>

    <h3 class="policy-terms-h2">Usage Data</h3>
    <p class="policy-terms-p">
      Usage Data is collected automatically when using the Service.
    </p>
    <p class="policy-terms-p">
      Usage Data may include information such as Your Device's Internet Protocol
      address (e.g. IP address), browser type, browser version, the pages of our
      Service that You visit, the time and date of Your visit, the time spent on
      those pages, unique device identifiers and other diagnostic data.
    </p>
    <p class="policy-terms-p">
      When You access the Service by or through a mobile device, We may collect
      certain information automatically, including, but not limited to, the type
      of mobile device You use, Your mobile device unique ID, the IP address of
      Your mobile device, Your mobile operating system, the type of mobile
      Internet browser You use, unique device identifiers and other diagnostic
      data.
    </p>
    <p class="policy-terms-p">
      We may also collect information that Your browser sends whenever You visit
      our Service or when You access the Service by or through a mobile device.
    </p>

    <h3 class="policy-terms-h2">
      Information Collected while Using the Application
    </h3>
    <p class="policy-terms-p">
      While using Our Application, in order to provide features of Our
      Application, We may collect, with Your prior permission:
    </p>
    <ul class="policy-terms-list">
      <li class="policy-terms-item">
        Pictures and other information from your Device's camera and photo
        library
      </li>
    </ul>
    <p class="policy-terms-p">
      We use this information to provide features of Our Service, to improve and
      customize Our Service. The information may be uploaded to the Company's
      servers and/or a Service Provider's server or it may be simply stored on
      Your device.
    </p>
    <p class="policy-terms-p">
      You can enable or disable access to this information at any time, through
      Your Device settings.
    </p>

    <h2 class="policy-terms-h2">Use of Your Personal Data</h2>
    <p class="policy-terms-p">
      The Company may use Personal Data for the following purposes:
    </p>
    <ul class="policy-terms-list">
      <li class="policy-terms-item">
        <strong>To provide and maintain our Service</strong>, including to
        monitor the usage of our Service.
      </li>
      <li class="policy-terms-item">
        <strong>To manage Your Account:</strong> to manage Your registration as
        a user of the Service. The Personal Data You provide can give You access
        to different functionalities of the Service that are available to You as
        a registered user.
      </li>
      <li class="policy-terms-item">
        <strong>For the performance of a contract:</strong> the development,
        compliance and undertaking of the purchase contract for the products,
        items or services You have purchased or of any other contract with Us
        through the Service.
      </li>
      <li class="policy-terms-item">
        <strong>To contact You:</strong> To contact You by email, telephone
        calls, SMS, or other equivalent forms of electronic communication, such
        as a mobile application's push notifications regarding updates or
        informative communications related to the functionalities, products or
        contracted services, including the security updates, when necessary or
        reasonable for their implementation.
      </li>
      <li class="policy-terms-item">
        <strong>To provide You</strong> with news, special offers and general
        information about other goods, services and events which we offer that
        are similar to those that you have already purchased or enquired about
        unless You have opted not to receive such information.
      </li>
      <li class="policy-terms-item">
        <strong>To manage Your requests:</strong> To attend and manage Your
        requests to Us.
      </li>
      <li class="policy-terms-item">
        <strong>For business transfers:</strong> We may use Your information to
        evaluate or conduct a merger, divestiture, restructuring,
        reorganization, dissolution, or other sale or transfer of some or all of
        Our assets, whether as a going concern or as part of bankruptcy,
        liquidation, or similar proceeding, in which Personal Data held by Us
        about our Service users is among the assets transferred.
      </li>
      <li class="policy-terms-item">
        <strong>For other purposes</strong>: We may use Your information for
        other purposes, such as data analysis, identifying usage trends,
        determining the effectiveness of our promotional campaigns and to
        evaluate and improve our Service, products, services, marketing and your
        experience.
      </li>
    </ul>
    <p class="policy-terms-p">
      We may share Your personal information in the following situations:
    </p>
    <ul class="policy-terms-list">
      <li class="policy-terms-item">
        <strong>With Service Providers:</strong> We may share Your personal
        information with Service Providers to monitor and analyze the use of our
        Service, to contact You.
      </li>
      <li class="policy-terms-item">
        <strong>For business transfers:</strong> We may share or transfer Your
        personal information in connection with, or during negotiations of, any
        merger, sale of Company assets, financing, or acquisition of all or a
        portion of Our business to another company.
      </li>
      <li class="policy-terms-item">
        <strong>With Affiliates:</strong> We may share Your information with Our
        affiliates, in which case we will require those affiliates to honor this
        Privacy Policy. Affiliates include Our parent company and any other
        subsidiaries, joint venture partners or other companies that We control
        or that are under common control with Us.
      </li>
      <li class="policy-terms-item">
        <strong>With business partners:</strong> We may share Your information
        with Our business partners to offer You certain products, services or
        promotions.
      </li>
      <li class="policy-terms-item">
        <strong>With other users:</strong> when You share personal information
        or otherwise interact in the public areas with other users, such
        information may be viewed by all users and may be publicly distributed
        outside.
      </li>
      <li class="policy-terms-item">
        <strong>With Your consent</strong>: We may disclose Your personal
        information for any other purpose with Your consent.
      </li>
    </ul>

    <h2 class="policy-terms-h2">Retention of Your Personal Data</h2>
    <p class="policy-terms-p">
      The Company will retain Your Personal Data only for as long as is
      necessary for the purposes set out in this Privacy Policy. We will retain
      and use Your Personal Data to the extent necessary to comply with our
      legal obligations (for example, if we are required to retain your data to
      comply with applicable laws), resolve disputes, and enforce our legal
      agreements and policies.
    </p>
    <p class="policy-terms-p">
      The Company will also retain Usage Data for internal analysis purposes.
      Usage Data is generally retained for a shorter period of time, except when
      this data is used to strengthen the security or to improve the
      functionality of Our Service, or We are legally obligated to retain this
      data for longer time periods.
    </p>

    <h2 class="policy-terms-h2">Transfer of Your Personal Data</h2>
    <p class="policy-terms-p">
      Your information, including Personal Data, is processed at the Company's
      operating offices and in any other places where the parties involved in
      the processing are located. It means that this information may be
      transferred to — and maintained on — computers located outside of Your
      state, province, country, or other governmental jurisdiction where the
      data protection laws may differ than those from Your jurisdiction.
    </p>
    <p class="policy-terms-p">
      Your consent to this Privacy Policy followed by Your submission of such
      information represents Your agreement to that transfer.
    </p>
    <p class="policy-terms-p">
      The Company will take all steps reasonably necessary to ensure that Your
      data is treated securely and in accordance with this Privacy Policy and no
      transfer of Your Personal Data will take place to an organization or a
      country unless there are adequate controls in place including the security
      of Your data and other personal information.
    </p>

    <h2 class="policy-terms-h2">Delete Your Personal Data</h2>
    <p class="policy-terms-p">
      You have the right to delete or request that We assist in deleting the
      Personal Data that We have collected about You.
    </p>
    <p class="policy-terms-p">
      Our Service may give You the ability to delete certain information about
      You from within the Service.
    </p>
    <p class="policy-terms-p">
      You may update, amend, or delete Your information at any time by signing
      in to Your Account, if you have one, and visiting the account settings
      section that allows you to manage Your personal information. You may also
      contact Us to request access to, correct, or delete any personal
      information that You have provided to Us.
    </p>
    <p class="policy-terms-p">
      Please note, however, that We may need to retain certain information when
      we have a legal obligation or lawful basis to do so.
    </p>

    <h2 class="policy-terms-h2">Disclosure of Your Personal Data</h2>
    <h3 class="policy-terms-h2">Business Transactions</h3>
    <p class="policy-terms-p">
      If the Company is involved in a merger, acquisition, or asset sale, Your
      Personal Data may be transferred. We will provide notice before Your
      Personal Data is transferred and becomes subject to a different Privacy
      Policy.
    </p>

    <h3 class="policy-terms-h2">Law enforcement</h3>
    <p class="policy-terms-p">
      Under certain circumstances, the Company may be required to disclose Your
      Personal Data if required to do so by law or in response to valid requests
      by public authorities (e.g. a court or a government agency).
    </p>

    <h3 class="policy-terms-h2">Other legal requirements</h3>
    <p class="policy-terms-p">
      The Company may disclose Your Personal Data in the good faith belief that
      such action is necessary to:
    </p>
    <ul class="policy-terms-list">
      <li class="policy-terms-item">Comply with a legal obligation</li>
      <li class="policy-terms-item">
        Protect and defend the rights or property of the Company
      </li>
      <li class="policy-terms-item">
        Prevent or investigate possible wrongdoing in connection with the
        Service
      </li>
      <li class="policy-terms-item">
        Protect the personal safety of Users of the Service or the public
      </li>
      <li class="policy-terms-item">Protect against legal liability</li>
    </ul>

    <h2 class="policy-terms-h2">Security of Your Personal Data</h2>
    <p class="policy-terms-p">
      The security of Your Personal Data is important to Us, but remember that
      no method of transmission over the Internet, or method of electronic
      storage is 100% secure. While We strive to use commercially acceptable
      means to protect Your Personal Data, We cannot guarantee its absolute
      security.
    </p>

    <h1 class="policy-terms-h1">Children's Privacy</h1>
    <p class="policy-terms-p">
      Our Service does not address anyone under the age of 13. We do not
      knowingly collect personally identifiable information from anyone under
      the age of 13. If You are a parent or guardian and You are aware that Your
      child has provided Us with Personal Data, please contact Us. If We become
      aware that We have collected Personal Data from anyone under the age of 13
      without verification of parental consent, We take steps to remove that
      information from Our servers.
    </p>
    <p class="policy-terms-p">
      If We need to rely on consent as a legal basis for processing Your
      information and Your country requires consent from a parent, We may
      require Your parent's consent before We collect and use that information.
    </p>

    <h1 class="policy-terms-h1">Links to Other Websites</h1>
    <p class="policy-terms-p">
      Our Service may contain links to other websites that are not operated by
      Us. If You click on a third-party link, You will be directed to that
      third-party site. We strongly advise You to review the Privacy Policy of
      every site You visit.
    </p>
    <p class="policy-terms-p">
      We have no control over and assume no responsibility for the content,
      privacy policies, or practices of any third-party sites or services.
    </p>

    <h1 class="policy-terms-h1">Changes to this Privacy Policy</h1>
    <p class="policy-terms-p">
      We may update Our Privacy Policy from time to time. We will notify You of
      any changes by posting the new Privacy Policy on this page.
    </p>
    <p class="policy-terms-p">
      We will let You know via email and/or a prominent notice on Our Service,
      prior to the change becoming effective and update the "Last updated" date
      at the top of this Privacy Policy.
    </p>
    <p class="policy-terms-p">
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </p>

    <h1 class="policy-terms-h1">Contact Us</h1>
    <p class="policy-terms-p">
      If you have any questions about this Privacy Policy, You can contact us:
    </p>
    <ul class="policy-terms-list">
      <li class="policy-terms-item">By email: webmaster@rigorcg.pt</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CarculatorPage",
};
</script>

<style type="text/css" scoped>
.policy-terms-h1 {
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0;
  margin-left: 30px;
  margin-right: 30px;
}

.policy-terms-h2 {
  font-size: 20px;
  font-weight: bold;
  padding: 8px 0;
  margin-left: 30px;
  margin-right: 30px;
}

.policy-terms-p {
  font-size: 16px;
  font-weight: normal;
  padding: 5px 0;
  margin-left: 30px;
  margin-right: 30px;
}

.policy-terms-item {
  font-size: 14px;
  font-weight: normal;
  padding: 5px 0;
  margin-left: 20px;
  margin-right: 30px;
}

.policy-terms-list {
  padding-left: 40px;
  list-style-type: disc;
  margin-right: 30px;
}
</style>
